import React, { useMemo } from 'react';
import { Dropdown, Icon } from 'components';
import useStyles from './LocaleSwitch.styles';
import { getLanguageFromLocale } from '../../../utils/locale';
import { useRouter } from 'next/router';

type LocaleSwitchProps = {
	className?: string;
};

const LocaleSwitch = (props: LocaleSwitchProps) => {
	const { className } = props;
	const classes = useStyles();
	const { locale, locales, pathname, query, push, asPath } = useRouter();

	const changeLocale = (value: string | null) => {
		if (value === null) {
			return;
		}
		push({ pathname, query }, asPath, { locale: value });
	};

	const isPreviewEnvironment = process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production';
	const options = useMemo(() => {
		return locales?.map((localeCode) => {
			const label = isPreviewEnvironment
				? localeCode.toUpperCase().replace('-', ' (') + ')'
				: localeCode;

			return {
				localeId: label,
				id: localeCode
			};
		});
	}, [locales]);

	if ((locales?.length ?? 0) < 2) {
		return null;
	}

	return (
		<Dropdown
			data-testid="locale-switch"
			value={locale}
			startIcon={<Icon className={classes.dropdownIcon} icon="language" />}
			options={options}
			minimal
			titleLocaleId={locale ? getLanguageFromLocale(locale) : ''}
			onChange={changeLocale}
			classes={{
				minimalText: classes.dropdownText,
				minimalTextField: classes.dropdown,
				inputBaseRoot: classes.dropdownInputBaseRoot,
				select: classes.dropdownSelect,
				input: classes.dropdownInput
			}}
			className={className}
			MenuProps={{
				classes: {
					paper: classes.menuPaper
				}
			}}
		/>
	);
};

export default LocaleSwitch;
