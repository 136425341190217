import { Dispatch, SetStateAction, useEffect } from 'react';
import _once from 'lodash/once';

import { useRouter } from '../';
import getStateByNonce from '../cookies/getStateByNonce';

type FallbackURLArgs = {
	url: string;
	destinationPackage?: unknown;
	createAbsolute?: boolean;
};

const redirect = _once(
	(
		nonce: string | Array<string> | null | undefined,
		router: ReturnType<typeof useRouter>,
		fallbackURLArgs?: FallbackURLArgs
	) => {
		const redirectState =
			nonce && !Array.isArray(nonce) && nonce.length > 0 ? getStateByNonce(nonce) : null;
		if (redirectState?.redirectTo) {
			router.push(redirectState?.redirectTo);
		} else if (fallbackURLArgs) {
			const { url } = fallbackURLArgs;
			router.push(url);
		} else {
			router.push('/');
		}
	}
);

/**
 * Reads the "state" query parameter from the URL, checks if there is some information associated
 * to the corresponding nonce and performs the redirection if the state has some "redirectTo" field
 * or if the fallbackURLArgs argument is provided.
 */
const useUrlState = (props: {
	setIsLoading?: Dispatch<SetStateAction<boolean>>;
	fallbackURLArgs?: FallbackURLArgs;
}) => {
	const { setIsLoading, fallbackURLArgs } = props;
	const router = useRouter();
	useEffect(() => {
		if (router.isReady) {
			const nonce = router.query['state'];
			redirect(nonce, router, fallbackURLArgs);
			setIsLoading && setIsLoading(false);
		}
	}, [router.isReady]);
};

export default useUrlState;
